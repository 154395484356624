$height: 70px;

.menu-container {

  &__logo {
    display: flex;
    flex: 0 0 190px;
    height: $height;
    justify-content: center;
    align-items: center;

    position: relative;
    z-index: 1;

    .desc{
      font-size: 12px;
      position: absolute;
      top: 16px;
      right: 58px;
      z-index: 2;
    }

    //&.pink{
    //  background: {
    //    image: url("/static/images/svg/logo.svg");
    //    position: left 0px center;
    //    repeat: no-repeat;
    //  };
    //}
    //
    //&.pink-hot{
    //  background: {
    //    image: url("/static/images/svg/logo_pink.svg");
    //    position: left 0px center;
    //    repeat: no-repeat;
    //  };
    //}
  }

  &__navigation {
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
  }

  &__link-item {
    font-weight: 400;
    padding: 0 14px;
    height: $height;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1.67px;
    position: relative;
    cursor: pointer;

    &:before{
      position: absolute;
      bottom: 10px;
      content: '';
      opacity: 0;
      border-radius: 1000px;
      height: 5px;
      width: 5px;
      display: block;
      left: 50%;
      transform: translateX(-50%);
      transition: $default-transition;
      background: $col_text_01;
    }

    &:hover{
      &:before{
        bottom: 15px;
        transition: $default-transition;
        opacity: 1;
      }
    }

    &_new{
      &:after{
        position: absolute;
        content: "new";
        display: block;
        font-size: 7px;
        font-weight: bold;
        color: red;
        right: 2px;
        top: 12px;
        transition: font-size 0.3s;
      }

      &:hover{
        &:after{
          font-size: 9px;
          transition: font-size 0.3s;
        }
      }
    }
  }
}
