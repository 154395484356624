.first-block {
  padding: 100px 0 60px 0;
  background: {
    image: url("/static/images/background/first-block.jpg");
    size: cover;
    position: center;
    repeat: no-repeat;
  };

  &__inner{
    display: flex;

    @include respond-down(S){
      flex-wrap: wrap;
    }
  }

  &__wrapper-phone{

    flex: 0 0 40%;
    display: flex;
    justify-content: center;

    @include respond-down(M){
      padding-right: 30px;
    }

    @include respond-down(S){
      flex: 0 0 100%;
      padding-right: 0px;
      margin-bottom: 30px;
      flex-wrap: wrap;
    }
  }

  &__inner-content{
    max-width: 550px;


    @include respond-down(M){
      max-width: none;
    }
  }

  &__phone {
    flex: 0 0 250px;
    display: flex;
    height: 459px;
    position: relative;
    z-index: 1;
    background: {
      size: contain;
      position: center;
      repeat: no-repeat;
    };
  }

 &__phone-up{

   position: absolute;
   left: 0;
   top: 0;
   right: 0;
   bottom: 0;
   clip: rect(0px, 0px, auto, 0px);

   z-index: 2;
   background: {
     size: contain;
     position: center;
     repeat: no-repeat;
   };


   animation: phone 5s infinite ease-in-out;
 }


  &__content {
      flex: 1 1 auto;
  }

  &__title {
    margin-bottom: 30px;

    @include respond-down(S){
      flex: 0 0 100%;
    }
  }

  &__description {
    display: block;
    margin-bottom: 30px;
    font-weight: 300;
    font-size: 22px;
    color: $col_text_01;
    letter-spacing: 1.8px;

    .line{
      position: relative;
      z-index: 2;

      &:before{
        content: '';
        height: 2px;
        width: 100%;
        background: $col_text_01;
        top: 50%;
        position: absolute;
        z-index: 1;

      }
    }

    @include respond-down(M){
      font-size: 18px;
    }

    @include respond(M){
      max-width: 400px;

    }

    @include respond-down(S){
      flex: 0 0 100%;

    }

    @include respond(S){
      padding: 0 50px;
    }
  }

  &__wrapper-button{
    display: flex;

    @include respond-down(S){
      justify-content: center;
    }
  }

  &__link-free {
    flex: 0 0 250px;


  }


  @keyframes phone {
    0%{
      clip: rect(0px, 0px, auto, 0px);
    }
    25%{
      clip: rect(0px, 200px, auto, 0px);
    }
    50%{
      clip: rect(0px, 80px, auto, 0px);
    }
    70%{
      clip: rect(0px, 300px, auto, 0px);
    }

    100%{
      clip: rect(0px, 0px, auto, 0px);

    }

  }
}