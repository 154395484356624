.layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100vw;
  &__header {

  }

  &__content {
    overflow: hidden;
  }

  &__footer {

  }
}