h1, h2, h3, h4, h5, h6 {
  font-family: $h-font;
}

.h1,.h2,.h3,.h4{
  font-family: $font;
  color: $col_text_01;
}

.h1{
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 600;
  color: $col_text_01;
  letter-spacing: 2.8px;
  text-align: left;
  line-height: 44px;



  @include respond-down(M){
    font-size: 25px;
  }

  @include respond-down(S){
    font-weight: 400;
    font-size: 30px;
  }

}
.h2{
  font-size: 43px;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;

  @include respond-down(M){
    font-size: 30px;
  }

  @include respond-down(S){
    font-weight: 600;
    font-size: 20px;


  }

}
.h3{
  font-size: 20px;
  letter-spacing: 0.1em;

  @include respond-down(M){
    font-size: 16px;
  }
}

.h4{
  font-size: 18px;
  letter-spacing: 0.1em;

  @include respond-down(M){
    font-size: 15px;
  }
}

.h5{
  font-size: 17px;
  letter-spacing: 0.1em;

  @include respond-down(S){
    font-size: 15px;
    letter-spacing: 0.05em;
  }

}