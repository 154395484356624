.confidentiality {
  padding: 100px 0 50px;
  &__wrapper-text {

  }

  &__wrapper-button {
    display: flex;
    justify-content: center;
  }
}