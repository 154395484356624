body {
  font-size: $body-font-size;
  font-family: $font;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;



  &.processed{
    position: fixed;

    .processed-block{
      display: block;
    }
  }

  .processed-block{
    display: none;
    position: fixed;
    z-index: 10000000;
    background: rgba(255, 237, 237, 0.76);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .wrapper-center-progress{
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      width: 100px;
      height: 100px;
      transform: translate(-50%, -50%);

      .progress-circle{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: {
          position: center;
          size: contain;
          repeat: no-repeat;
          image: url("/static/images/base/process_circle.png");

        };
        animation: rot infinite 3s linear;



      }

      .wrapper-svg-progress{
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        //animation: rot infinite 2s;

        .svg-opener{
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}

@keyframes rot {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.container {
  padding: 0 4%;
  max-width: 1400px;
  margin: 0 auto;

  &.no-margin{
    margin: 0;
  }

  @include respond(M){
    padding: 0 7%;
  }
  @include respond-down(S){
    padding: 0 5%;
  }
}


button{
  border: none;
  background: transparent;
  width: auto;
  height: auto;
  padding: 0;
}
textarea{
  border: none;
  background: transparent;
  height: 100%;
  width: 100%;
  resize: none;
  font-size: 14px;
  color: $col_text_01;

}

input{
  border: none;
  height: 100%;
  width: 100%;
  background: transparent;
  color: $col_text_01;
  font-size: 14px;

}


a{

  text-decoration: none;
}

a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus{
  text-decoration: none;
  outline: 0!important;
  outline-color: transparent!important;
  outline-width: 0!important;
  outline-style: none!important;
  box-shadow: 0 0 0 0 rgba(0,123,255,0)!important;
}

.fix{
  overflow: hidden;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

[data-debug="debug"] #DEBUG {
  position: fixed;
  padding: 5px;
  opacity: 0.5;
  background: #f00;
  color: #fff;
  border-radius: 5px;
  left: 10px;
  bottom: 10px;
  z-index: 99999;
  &:after {
    content: 'XL';
    @include respond-down(L)  { content: 'L';  }
    @include respond-down(M)  { content: 'M';  }
    @include respond-down(S)  { content: 'S';  }
    @include respond-down(XS) { content: 'XS'; }
  }


}