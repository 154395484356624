.icon-block {
  padding: 44px 0 60px 0;

  @include respond-down(M){
    padding: 40px 0 20px 0;
  }

  &__list {
    display: flex;

    @include respond-down(S){
      flex-wrap: wrap;
    }
  }

  &__item {
    flex: 0 0 33%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include respond-down(S){
      flex: 0 0 100%;
      margin-bottom: 40px;
    }
  }

  &__icon {
      flex: 0 0 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    height: 74px;

    @include respond-down(S){
    margin-bottom: 10px;
    }
  }

  &__title {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 18px;
    color: $col_text_01;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 8px;

    @include respond-down(M){
      font-size: 16px;
    }
  }

  &__description {
    display: flex;
    justify-content: center;
    flex: 0 0 100%;
    font-size: 16px;
    color: #141217;
    letter-spacing: 1.33px;

    @include respond-down(M){
      font-size: 12px;
    }

  }
}