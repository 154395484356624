.footer {
  display: block;
  background: {
    repeat: no-repeat;
    position: center;
    size: cover;
    image: url("/static/images/background/footer3.jpg");
  };
  opacity: 0.8;
  padding-top: 30px;

  &__wrapper-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;

    @include respond-down(M){

      flex-wrap: wrap-reverse;
      justify-content: flex-start;
    }
  }

  &__contact {
    flex: 0 1 400px;

    @include respond-down(M){
      flex: 1 1 auto;
    }

  }

  &__wrapper-logo {
    margin-bottom: 40px;
  }

  &__link-logo {

  }

  &__wrapper-lists {
    display: flex;
    justify-content: space-between;

    @include respond-down(M){
      justify-content: space-around;
    }

  }

  &__title-list {
    text-transform: uppercase;
    margin-bottom: 35px;

    @include respond-down(M){
      font-size: 14px;
      letter-spacing: 0.05em;
      margin-bottom: 15px;
    }


  }

  &__list {
      @include respond-down(S){
        min-width: 142px;
      }
  }

  &__item {
    display: block;
    margin-bottom: 10px;


  }

  &__footer-contact-link {
    position: relative;
    display: flex;
    align-items: center;

    .icon{
      margin-right: 15px;
      width: 30px;
      height: 30px;
      transition: $default-transition;
    }
    .icon-select{
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
    }

    &:hover{
      .icon{
        opacity: 0;
      }
      .icon-select{
        opacity: 1;
      }
    }

    .name{
      text-transform: uppercase;
      font-family: $font2;
      font-size: 20px;
      color: #000000;
      letter-spacing: 1.5px;

      @include respond-down(M){
        font-size: 16px;
      }
    }

  }

  &__form {
    flex: 1 1 auto;
  }

  &__author{
    display: flex;
    justify-content: flex-end;
    background: $col_pink_main;
    height: 44px;
    align-items: center;

    .logo-autor{
      color: $col_text_01;

    }
  }
}