$max-row-width: 7000;
$grid-columns: 12;
$L: 1440;
$M: 1024;
$S: 768;
$XS: 576;


$grid: (
        XL: (to: $max-row-width, from: $L, gutter: 12),
        L: (to: $L,  from: $M,  gutter: 12),
        M: (to: $M,  from: $S,  gutter: 12),
        S: (to: $S,  from: $XS, gutter: 12),
        XS: (to: $XS, from: 0,   gutter: 0)
);

:export { L: $L; M: $M; S: $S; XS: $XS; }


$body-font-size: 16px;
$font: 'Roboto', sans-serif;
$font2: 'SignPainter-HouseScript', sans-serif;
$t: transparent;

$h-font: $font;
$default-transition: all 0.4s;
$default-transition-fast: all 0.2s;
$primary-color: red;


$col_pink_main: #FDEAE6;
$col_pink_dark: #e9d6d2;
$col_pink_filter: #F6EAE7;
$col_pink_tag: #f7f0e6;

$col_red_01: #FF6060;
$col_red_02: #ff6d6d;



$col_brown: #AE8D77;
$col_ping: #FCEBE8;
$col_telegram_01: #0c8dd1;
$col_telegram_02: #075d89;

$col_text_01: rgba(5, 5, 5, 1);
$col_text_02: #545157;
$col_text_03: #78757b;
$col_text_04: #fd4e9d;

$col_hot_pink: #fd4e9d;
$col_hot_pink_dark: #dc3d85;


$gradient-standart: linear-gradient(-90deg, #FFF 0%, #fdeae673 100%);

$default-transition: all 0.4s;
