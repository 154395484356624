.order-telegram {
  padding-top: 100px;

  &__inner{
    display: flex;
    justify-content: center;
  }
}

@include respond-up(M){
  .order-telegram {
    margin-bottom: 100px;

  }
}


@include respond-down(S){
  .order-telegram {
    margin-bottom: 50px;

  }
}
