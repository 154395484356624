.news-view {
  padding: 100px 0 90px;

  &__wrapper-flex{
    display: flex;

    @include respond-down(M){
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__wrapper-image {
    flex: 0 0 500px;

    @include respond-down(M){
      margin-bottom: 30px;
      flex: 0 0 400px;

    }


    @include respond-down(XS){
      flex: 0 0 300px;
      margin-bottom: 30px;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 500px;
    margin-bottom: 30px;
    background: {
      size: cover;
      position: top center;
      repeat: no-repeat;
    };

    @include respond-down(L){
      height: 500px;
    }

    @include respond-down(M){
      height: 400px;
    }

    @include respond-down(XS){
      height: 300px;
    }
  }

  &__wrapper-text {
    padding-left: 40px;
    flex: 1 1 auto;

    @include respond-down(S){
      flex: 0 0 100%;
      padding-left: 0px;
    }
  }

  &__text{
    margin-bottom: 50px;
  }

  &__wrapper-breadcrumbs{
    margin-bottom: 40px;
  }

  &__wrapper-link{
    display: flex;
    justify-content: center;
  }
}