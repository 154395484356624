.breadcrumbs {

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    color: $col_text_02;

    &_delimiter {
      font-size: 12px;
    }
  }

  &__link {
    font-size: 12px;
    color: $col_text_02;
    text-decoration: $col_text_02;
  }
}