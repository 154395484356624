.instruction {
  overflow: hidden;
  background-image: $gradient-standart;
  padding-top: 55px;
  position: relative;
  background: {
    image: url("/static/images/background/th.jpg");
    repeat: no-repeat;
    position: center;
    size: cover;
  };
  z-index: 1;

  @include respond-down(M){
    padding-top: 30px;
  }

  &__title {
    position: relative;
    z-index: 3;
  }


  &__logo-background{
    right: -250px;
    top: 20%;

    z-index: 2;
    position: absolute;
    opacity: 0.05;
    animation: rot infinite 20s linear;



    svg.svg-opener{
      width: 500px;
      height: 500px;
    }

    @include respond-down(S){
      right: -150px;

      svg.svg-opener{
        width: 300px;
        height: 300px;
      }
    }
  }


  &__inner-item{
    display: flex;
    flex-direction: column;

  }

  &__list {
      position: relative;
      z-index: 3;

      .slick-arrow{
        position: absolute;
        z-index: 300;
        overflow: hidden;
        height: 60px;
        bottom: 20px;
        text-indent: -10000px;
        display: block;
        width: 60px;
        transition: $default-transition;
        background: {
          repeat: no-repeat;
          position: center;
          size: 20px 45px;
        };

        @include respond-down(M){
          width: 40px;
          background-size: 15px 40px;
        }

      }

      .slick-next {
        left: 60px;
        background-image: url("/static/images/svg/arrow-right.svg");

        &:hover{
          right: 10px;
        }
      }

      .slick-prev {
        left: 0;
        background-image: url("/static/images/svg/arrow-left.svg");

        &:hover{
          left: 10px;
        }
      }

      .slick-dots{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 40px;

        @include respond-down(S){
          justify-content: flex-end;
          padding-top: 30px;
          padding-bottom: 50px;
        }

        li{
          overflow: hidden;
          text-indent: -1000px;
          border-radius: 5px;
          display: block;
          height: 8px;
          width: 8px;
          background: gray;
          margin: 0 5px 0 5px;
          transition: $default-transition;

          &:hover{
            background: #4b4b4b;
          }

          &.slick-active{
            background: #2b2b2b;
            box-shadow: 0px 0px 0px 2px #2b2b2b;

          }

          @include respond-down(M){
            li{
              height: 5px;
              width: 5px;

              &.slick-active{
                background: #2b2b2b;
                box-shadow: 0px 0px 0px 1px #2b2b2b;

              }
            }
          }
        }
      }
  }

  &__wrapper-list{

  }

  &__item {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
  }

  &__description {
    padding-left: 50px;
    padding-right: 100px;
    display: block;
    font-size: 16px;
    margin-bottom: 20px;
    color: $col_text_02;
    letter-spacing: 0.1em;
    line-height: 20px;

    @include respond-down(M){
      font-size: 14px;
      padding: 0 50px 0 20px;
      overflow: hidden;
    }

    @include respond-down(S){
      min-height: 60px;
      flex: 0 0 100%;
      letter-spacing: 0.05em;
      padding-left: 0px;
      padding-right: 20px;
    }

  }

  &__wrapper-link{
    display: flex;
    justify-content: center;
    flex: 0 0 100%;
    @include respond-down(S){

    }
  }

  &__link {
    width: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    background: {
      repeat: no-repeat;
      position: center;
      size: cover;
    };

    .circle{
      border-radius: 10000px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 85px;
      width: 85px;
      background-color: $col_pink_main;
      transition: $default-transition;

      svg{
        padding-left: 10px;
        padding-top: 2px;

      }

      @include respond-down(M){
        height: 35px;
        width: 35px;
        border: 1px solid #4b4b4b;

        svg{
          width: 10px;
          padding-left: 2px;
        }
      }
    }

    &:hover{
      .circle{
        background-color: $col_pink_dark;
      }
    }

    @include respond-down(M){
      width: 400px;
      height: 222px;
    }

    @include respond-down(S){
      width: 280px;
      height: 155px;
    }
  }



}