.about-me {
  padding-top: 40px;
  padding-bottom: 40px;
  &__content {
    display: flex;

    @include respond-down(S){
      flex-wrap: wrap;
    }
  }

  &__wrapper-flex{
    flex: 0 0 30%;
    margin-right: 40px;
    display: flex;
    position: relative;

    @include respond-down(S){
      margin-right: 0;
      flex: 0 0 100%;
      justify-content: center;
      height: 250px;
      margin-bottom: 20px;
    }

  }

  &__photo{

    flex: 0 0 40%;
    background: {
      position: top center;
      size: contain;
      repeat: no-repeat;
    };
  }

  &__text {
    flex: 1 1 auto;
    font-size: 16px;
    padding-left: 40px;
    position: relative;

    &:before{
      opacity: 0;
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 50px;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
      background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }


    @include respond-down(S){
      max-height: 250px;
      overflow: hidden;


      &.opened{
        max-height: none ;

        &:before{
          opacity: 0;
        }

      }

      &:before{
        opacity: 1;
      }
    }



  }

  &__button-read-more{
    color: $col_text_01;
    padding: 15px;
    display: block;
    font-weight: 600;

    &.opened{
      display: none;
    }

  }

}