.source {
  padding: 150px 0;

  &__title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
  }

  &__list {
    padding-left: 30px;

    &_row{
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    margin-bottom: 14px;
    margin-left: 15px;

    &_flex{
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  &__button{
    flex: 0 0 200px;
    text-align: center;
  }

  &__title-product{
    font-weight: bold;
    font-size: 20px;
  }

  &__title-source{
    flex: 0 0  100px;
    font-weight: bold;
    font-size: 15px;
  }

  &__value{
    display: flex;
    align-items: center;
  }

  &__product{
    margin-bottom: 30px;
  }
}

._accordion_close{
  display: none;
}
