.footer-form {
  display: flex;
  max-width: 500px;
  flex-wrap: wrap;
  position: relative;

  &.success{
    opacity: 0.5;

    .footer-form__success-holder{
      display: flex;
    }
  }


  @include respond-down(M){
    max-width: none;
    flex: 0 0 100%;
    padding-top: 0px;
    margin-bottom: 30px;
  }

  &__success-holder{
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    position: absolute;
    background: rgba(240, 255, 244, 0.49);
    display: none;
    justify-content: center;
    align-items: center;

    .svg-ok{
        .svg-ok-inner{
          fill:  green;
        }
        .svg-ok-circle{
          fill: $col_text_03;
        }
    }
  }


  &__title {
    flex: 0 0 100%;
    margin-bottom: 15px;
    font-weight: 400;
    text-transform: uppercase;

    .answer{
      font-weight: 700;
      text-transform: uppercase;
    }


  }

  &__wrapper-list {
    flex: 0 0 50%;
    margin-bottom: 15px;

    @include respond-down(M){
      flex: 0 0 auto;
      margin-bottom: 10px;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
  }

  &__item {
    @include respond-down(M){
      margin-right: 10px;
    }
  }

  &__social-button {
    position: relative;
    display: flex;
    align-items: center;

    .icon{
      width: 40px;
      height: 40px;
      transition: $default-transition;

      @include respond-down(M){
        height: 30px;
        width: 30px;
      }
    }
    .icon-select{
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
    }

    &:hover{
      .icon{
        opacity: 0;
      }
      .icon-select{
        opacity: 1;
      }
    }

    &.select{
      .icon{
        opacity: 0;
      }
      .icon-select{
        opacity: 1;
      }
    }
  }

  &__wrapper-field-contact {
    flex: 0 0 50%;

    @include respond-down(M){
      flex: 1 1 auto;
      margin-bottom: 20px;
    }
  }

  &__wrapper-field-text{
    flex: 0 0 100%;
  }



  &__wrapper-button {
    display: flex;

    .confidential{
      font-size: 10px;
      color: $col_text_01;
      margin-right: 15px;

      .confidential-link{
        font-weight: 700;
        border: 1px solid transparent;
        transition: $default-transition;

        &:hover{
          border-bottom: 1px solid $col_text_01;
        }
      }


    }

    .button{
      @include respond-down(M){
        margin-bottom: 20px;
        width: 100%;
        max-width: 250px;
      }
    }

    @include respond-down(M){
      flex-wrap: wrap-reverse;
    }
  }

  &__hidden-field{
    display: none;
  }

}