.text-raw{
  h4{
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 1.3;
    margin-bottom: 10px;
    font-weight: 600;
  }
  p{
    margin-bottom: 15px;
    display: block;
    letter-spacing: 0.05em;
    line-height: 1.3;

    &.mini-margin{
      margin-bottom: 5px;
    }

  }

  strong{
    font-weight: 700;
  }

  em{
    font-style: italic;
  }

  ul{
    margin-bottom: 20px;

    li{
      padding-top: 5px;
      position: relative;
      padding-left: 40px;
      padding-bottom: 5px;
      letter-spacing: 0.05em;

      &:before{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        content: '';
        display: block;
        height: 24px;
        width: 24px;
        background: {
          position: center;
          repeat: no-repeat;
          size: cover;
          image: url("/static/images/svg/opener.svg");
        };
      }
    }
  }

  ol{
    margin-bottom: 20px;
    list-style-type: none; /* Убираем исходные маркеры */
    counter-reset:  item; /* Обнуляем счетчик списка */

    li{
      position: relative;
      padding-left: 25px;
      padding-bottom: 5px;
      letter-spacing: 0.05em;

      &:before{
        font-weight: 600;
        position: absolute;
        left: 0;
        top: 0;
        content: counter(item) '.'; /* Добавляем к числам скобку */
        counter-increment: item; /* Задаём имя счетчика */
        display: block;
      }
    }
  }


  @include respond-down(M){
    font-size: 14px;

    p{
      margin-bottom: 8px;
    }

    ul{
      margin-bottom: 20px;

      li{
        position: relative;
        padding-left: 20px;
        padding-bottom: 5px;
        letter-spacing: 0.05em;

        &:before{
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);

          content: '';
          display: block;
          height: 14px;
          width: 14px;
          background: {
            position: center;
            repeat: no-repeat;
            size: cover;
            image: url("/static/images/svg/opener.svg");
          };
        }
      }
    }

  }
}