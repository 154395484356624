.wrapper-title{
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  @include respond-down(M){
    margin-bottom: 30px;
  }

  &__title{
    @include respond-down(M){
      flex: 1 1 504px;
      padding-right: 15px;
    }

    @include respond-down(S){
      text-align: left;
    }

    @include respond(XS){
      padding-right: 10px;
      flex: 1 1 236px;
    }
  }

  &__link{
    font-size: 20px;
    letter-spacing: 1.67px;
    color: $col_text_01;

    &:visited{
      color: $col_text_01;
    }
  }

  &__back{
    font-size: 25px;
    color: $col_text_01;
    letter-spacing: 1.86px;
  }


}

.wrapper-title-h4{
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @include respond-down(M){
    margin-bottom: 10px;
  }

  &__title{
    @include respond-down(M){
      flex: 0 0 100%;
      text-align: left;
    }
  }

  &__link{
    font-size: 20px;
    letter-spacing: 1.67px;
    color: $col_text_01;

    &:visited{
      color: $col_text_01;
    }
  }

  &__back{
    font-size: 25px;
    color: $col_text_01;
    letter-spacing: 1.86px;
  }


}