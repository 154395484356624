.wrapper-hidden-field{
  display: none;
}

.wrapper-input-field{
  padding: 0 2%;
  background: #FFFFFF;
  border: 2px solid $col_brown;
  border-radius: 5px;
  height: 40px;

  .iti--allow-dropdown{
    height: 100%;
    width: 100%;
  }
}

.wrapper-textarea-field{
  height: 100px;
  background: #FFFFFF;
  border: 2px solid $col_brown;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
}

.wrapper-input-field, .wrapper-textarea-field{
  position: relative;

  .errors{
    z-index: 1;

    li{
      z-index: 1;
      position: absolute;
      color: red;
      font-size: 10px;
      left: 0;
      bottom: -15px;
    }

  }
  textarea{
    &::placeholder{
      font-family: $font;
      font-size: $body-font-size;
    }
  }
}

.wrapper-checkbox-field{
  input {
    display: none;
  }

  label{
    padding-left: 30px;
    display: flex;
    align-items: center;
    height: 21px;
    width: 100%;
    font-size: 12px;
    letter-spacing: 0.9px;

    background: {
      repeat: no-repeat;
      position: bottom left;
      size: contain;
    };

  }

  input + label{
    background-image: url("/static/images/svg/checkbox.svg");

  }

  input:checked + label{
    background-image: url("/static/images/svg/checkbox-cheked.svg");
  }
}


.radio-list{
  li{
    margin-right: 10px;
    margin-bottom: 3px;
    input{
      display: none;
    }

    input + label{
      display: block;
      background: transparent;
      padding: 5px 5px;
      border-radius: 4px;
      transition: $default-transition-fast;

      @include respond-up(L){
        &:hover{
          background: rgba(248, 105, 133, 0.66);
        }
      }




    }

    input:checked + label{
      background: #f86985;
    }
  }
}

.form-product-bot{

  .label{
    font-size: 32px;
  }

  .radio-list{
    width: 100%;
    display: flex;
    flex-wrap: wrap;


    input {
      display: none;
    }

    input + label{
      display: block;
      background: transparent;
      padding: 5px 5px;
      border-radius: 4px;
      transition: $default-transition-fast;

      @include respond-up(L){
        &:hover{
          background: $col_hot_pink;
        }
      }
    }



    input:checked + label{
      background: $col_hot_pink_dark;
    }
  }
}



