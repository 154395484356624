.catalog {
  background-image: $gradient-standart;
  padding-top: 100px;
  min-height: 800px;
  &__title{
    font-weight: bold;
  }

  &__form-wrapper-button{
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-down(M){
      flex-wrap: wrap;
    }
  }

  &__clear-filter{
    @include respond-down(XS){
      order: 2;

    }
  }

  &__button-search{
    @include respond-down(M){
      flex: 0 1 235px;
    }

    @include respond(XS){
      flex: 0 0 100%;
      margin-bottom: 20px;
      order: 1;

    }
  }

  &__form-wrapper-flex{
    display: flex;

    @include respond-down(S){
      flex-wrap: wrap;
    }
  }

  &__form-wrapper-search{
    flex: 0 0 30%;

    @include respond-down(S){
      flex: 0 0 100%;
    }
  }

  &__form-search{
    background: white;
    border: 1px solid black;
    transition: $default-transition;

    @include respond-up(L){
      &:hover{
        background: $col_red_01;
      }
    }

  }

  &__form-wrapper-tags{
    display: flex;
    flex-wrap: wrap;
  }

  &__form-wrapper-h5{
    margin-bottom: 20px;
  }
  &__form-name-column{
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__form {
    margin-top: 20px;
    border-radius: 10px;
    padding: 30px;
    background-color: #f5e5f1;
    margin-bottom: 20px;


    @include respond-down(M) {
      padding: 15px;
    }
    @include respond-down(S) {
    }
    @include respond-down(XS) {
    }

    .radio-list{
      display: flex;
      margin-bottom: 30px;
      flex-wrap: wrap;

    }

    .wrapper-input-field{
      margin-bottom: 20px;
    }


  }

  &__list {
    display: flex;
    margin: 0 -12px;
    flex-wrap: wrap;
    padding-bottom: 40px;

    .product-preview{
      flex: 0 0 25%;
    }

    @include respond-down(M){
      .product-preview{
        flex: 0 0 50%;
      }
    }

    @include respond-down(S){
      .product-preview{
        flex: 0 0 50%;
      }
    }
    @include respond-down(XS){
      .product-preview{
        flex: 0 0 100%;
      }
    }
  }

  &__clear-filter{
    font-size: 14px;
    transition: $default-transition-fast;
    display: flex;
    align-items: center;

    .text{
      color: $col_text_02;
      margin-right: 10px;
    }

    svg{
      width: 14px;
      height: 14px;

      .reload{
        fill: $col_text_02;

      }
    }

    &:hover{
      .text{
        color: $col_text_01;
      }

      svg{
        .reload {
          fill: $col_text_01;
        }
      }
    }


  }

  &__wrapper-breadcrumbs{
    margin-bottom: 30px;
  }

  &__button-open-filter{
    margin-bottom: 30px;
    max-width: 183px;

    span{
      &.hidden{
        display: none;
      }
    }
  }


}