.product {

  //background-image: linear-gradient(-90deg, #FFF9F7 0%, #FDEAE6 100%);
  padding: 100px 0  90px;

  @include respond-down(M){
    padding: 100px 0  40px;
  }

  @include respond-down(S){
    padding: 100px 0  20px;
  }

  &__content {
    display: flex;
    padding-top: 50px;
    margin-bottom: 50px;

    @include respond-down(M){
      justify-content: center;
      padding-top: 30px;
      margin-bottom: 50px;
      flex-wrap: wrap;
    }
    @include respond-down(S){
      padding-top: 20px;
      margin-bottom: 50px;
    }
  }

  &__wrapper-image {
    display: block;
    flex: 0 0 550px;
    width: 550px;

    @include respond(L){
      margin-left: 80px;
      flex: 0 0 400px;
      width: 400px;
    }

    @include respond-down(M){
      flex: 0 0 500px;
      width: 500px;
      margin-bottom: 30px;
    }

    @include respond-down(S){
      flex: 0 0 337px;
      width: 337px;
      margin-bottom: 15px;
    }
    @include respond-down(XS){
      flex: 1 1 auto;
      width: 100%;
      margin-bottom: 15px;
    }
  }

  &__about {
    padding-left: 70px;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    align-items: flex-start;

    @include respond-down(M){
      padding-left: 0px;
    }
  }

  &__title-text {
    flex: 0 0 100%;
    font-weight: 600;
    font-size: 18px;
    color: #141217;
    letter-spacing: 0.2em;
    margin-bottom: 15px;

    @include respond-down(S){
      font-size: 16px;
    }
  }

  &__text {
    font-size: 14px;
    flex: 0 1 700px;
    color: #141217;
    letter-spacing: 1.3px;
    line-height: 25px;
    margin-bottom: 15px;

    @include respond-down(S){
      font-size: 14px;
    }
  }

  &__wrapper-price {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }


  &__price-name {
    font-weight: 700;
    font-size: 18px;
    color: $col_text_01;
    letter-spacing: 1.3px;
    display: block;
    margin-right: 20px;
  }

  &__price {
    font-weight: 400;
    color: $col_text_01;
    display: flex;
    align-items: center;
    font-size: 20px;
    letter-spacing: 2px;
    margin-right: 15px;


    .value{
      margin-right: 5px;
    }

    .svg-rub{
      height: 18px;
      width: 15px;
    }
  }

  &__old-price{
    z-index: 1;
    position: relative;
    display: flex;
    font-weight: 400;
    font-size: 15px;
    color: $col_text_01;
    letter-spacing: 1.3px;

    &:before{
      position: absolute;
      top: 50%;
      content: '';
      background: #940000;
      height: 2px;
      left: 0;
      right: 0;
      display: block;
    }

    .svg-rub{
      height: 15px;
      width: 10px;
    }
  }

  &__instruction {
    font-size: 12px;
    line-height: 1.3em;
    letter-spacing: 0.02em;
    margin-bottom: 30px;
  }
  &__sold-exclusive{
    margin-bottom: 30px;
    position: relative;
    padding: 40px 0 40px;
  }

  &__sold-exclusive-button{

      width: 250px;
      opacity: 0.5;
  }

  &__sold-exclusive-background{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: {
      position:  center;
      image: url("/static/images/base/sold.png");
      repeat: no-repeat;
      size: contain;
    };
  }

  &__form {
    flex: 0 0 100%;
    margin-bottom: 40px;
  }

  &__list-meta{

  }

  &__wrapper-tags{
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;
  }

  &__tags-title{
    flex: 0 0 100%;
    font-weight: 600;
    font-size: 18px;
    color: #141217;
    letter-spacing: 0.2em;
    margin-bottom: 15px;

    @include respond-down(S){
      font-size: 16px;
    }
  }

  &__tags-list{
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;
    border-radius: 10px;
    background: white;
    padding: 10px;
  }

  &__tag-item{
    margin-right: 10px;
  }

  &__tag-link{
    color: $col_text_03;
    letter-spacing: 0.05em;
    font-size: 11px;

    &:hover{
      color: $col_text_02;
    }
  }

  &__special-marks{
    display: flex;
    flex: 0 0 100%;
    margin-bottom: 22px;
    align-items: center;

    @include respond-down(L){
      flex-wrap: wrap;
    }
  }

  &__special-marks-title{
    margin-right: 30px;
    font-weight: 600;
    font-size: 18px;
    color: #141217;
    letter-spacing: 0.2em;

    @include respond-down(L){
      margin-bottom: 10px
    }

    @include respond-down(S){
      font-size: 16px;
    }
  }

  &__special-marks-list{
    display: flex;


  }

  &__special-marks-item{
    margin-right: 15px;
  }

  &__checkbox-field{
    margin-bottom: 35px;
  }

  &__list-contact{

    display: flex;

  }

  &__item-contact{
    margin-right: 15px;
  }

  &__wrapper-list-contact{
    margin-bottom: 10px;
  }

  &__answer{
    color: $col_text_02;
    font-size: 12px;
    display: block;
    margin-bottom: 10px;

    .answer{
      font-weight: 700;
    }
  }

  &__field{
    max-width: 250px;
    margin-bottom: 30px;

    @include respond-down(S){
      max-width: none;
    }
  }

  &__button{
    margin-bottom: 8px;
  }

  &__confidential{
    font-size: 10px;
    max-width: 400px;
    display: block;
  }

  &__email{
    font-weight: 400;
    font-size: 12px;
    color: $col_text_02;
    display: block;
    margin-bottom: 10px;
  }

  &__contact-block{
    display: block;

    &.hidden{
      display: none;
    }
  }

  &__image-list-big{
    width: 100%;
    height: 733px;
    margin-bottom: 30px;

    @include respond-down(L){
      height: 400px;
    }

    @include respond-down(M){
      height: 667px;
    }

    @include respond-down(S){
      height: 450px;
    }

    @include respond-down(XS){
      height: 384px;
    }

  }

  &__image-item-big{
    height: 100%;
    width: 100%;

    .image{
      overflow: hidden;
      height: 733px;
      width: 100%;
      display: block;
      background: {
        repeat: no-repeat;
        position: center;
        size: cover;
      };

      @include respond-down(L){
        height: 400px;
      }

      @include respond-down(M){
        height: 667px;
      }

      @include respond-down(S){
        height: 450px;
      }

      @include respond-down(XS){
        height: 384px;
      }
    }

  }

  &__image-list-small{
    width: 100%;

    .slick-current{
      .image{
        border: 1px solid #AE8D77;



      }
    }
  }

  &__image-item-small{
    display: block;

    .image{
      border-radius: 10px;
      overflow: hidden;
      display: block;
      width: 70px;
      height: 70px;
      background: {
        repeat: no-repeat;
        position: center;
        size: cover;
      };
    }

    @include respond-down(L){
      .image{
        width: 50px;
        height: 50px;
      }
    }

    @include respond-down(M){
      .image{
        width: 60px;
        height: 60px;
      }
    }

    @include respond-down(XS){
      .image{
        border-radius: 5px;
        height: 40px;
        width: 40px;
      }
    }
  }
}