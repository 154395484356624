.bot-sale {
  background: #edfffd;

  &__offer {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-bottom: 64px;

    &:after{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background: rgb(2,0,36);
      background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(0,0,0,0.7189469537815126) 61%, rgba(0,0,0,1) 100%);
      z-index: 3;
    }
  }

  &__offer-video {
    object-fit: cover;
    object-position: bottom center;
    min-width: 100%;
    min-height: 100%;
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &__offer-container{
    position: relative;
    padding-top: 200px;
    z-index: 4;
  }

  &__offer-title{
    letter-spacing: 3.1px;
    color: $col_text_04;
    text-align: right;
  }

  &__offer-for{
    color: $col_text_04;
    text-align: right;
    letter-spacing: 3.1px;
    font-weight: bold;
    display: block;
  }

  &__offer-description{
    color: $col_text_04;
    text-align: right;
    letter-spacing: 2.1px;
    font-size: 22px;
    display: block;
    max-width: 500px;
    margin-bottom: 100px;
  }

  &__offer-button{
    margin: 0 0 0 auto;
    max-width: 250px;
  }

  &__form{
    margin-bottom: 40px;
  }

  &__form-label{
    label.label{
      display: block;
      margin-bottom: 24px;
    }
  }

  &__form-input{
    display: flex;
    flex-direction: column;
    align-items: self-start;

    li{
      label {
        margin-right: 20px;
      }
    }


  }

  &__form-all{
    margin-right: 20px;
    display: block;
    background: transparent;
    padding: 5px 5px;
    border-radius: 4px;
    transition: $default-transition-fast;
    color: $col_text_01;
    margin-bottom: 8px;

    &:visited{
      color: $col_text_01;
    }

    @include respond-up(L){
      &:hover{
        background: $col_hot_pink;
      }
    }

    &._active{
      background: $col_hot_pink_dark;
    }
  }

  &__content {
    display: grid;
    grid-column-gap: 5%;
  }

  &__content-title {
    display: block;
  }

  &__content-description {
    font-size: 22px;
    display: block;
    margin-bottom: 87px;
    letter-spacing: 2px;
    max-width: 400px;
  }

  &__content-text {
    font-weight: bold;
  }

  &__content-icons {
    display: grid;
    row-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1.2fr 0.8fr;
  }

  &__content-icon {
    display: flex;
    flex-direction: column;
    align-items: center;


    &_phone{
      grid-column: 1/2;
      grid-row: 1/2;
    }
    &_150{
      grid-column: 2/3;
      grid-row: 1/2;
    }
    &_test{
      grid-column: 1/2;
      grid-row: 2/3;
    }

    span{
      text-align: center;
    }
  }

  &__ease {
    position: relative;
    z-index: 1;

    &:after{
      z-index: 2;
      position: absolute;
      content: '';
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: radial-gradient(49.91% 49.91% at 48.75% 39.46%, rgba(0, 0, 0, 0.39) 0%, rgba(0, 0, 0, 0.2886) 100%);
    }
  }

  &__ease-image{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 2;
  }

  &__ease-inner{
    width: 900px;
    margin: 0 auto;
  }

  &__ease-content {
    position: relative;
    z-index: 4;
  }

  &__ease-title {
    display: block;
    color: white;
  }

  &__ease-description {
    font-size: 18px;
    letter-spacing: 0.11em;
    display: block;
    margin-bottom: 55px;
    color: white;
    max-width: 550px;
  }

  &__ease-button{
    max-width: 267px;
    color: white;
  }

  &__works-confirm {
    display: flex;
  }

  &__works-slider{
    margin-bottom: 60px;
  }

  &__works-wrapper-button{
    display: flex;
  }

  &__works-button{
    max-width: 202px;
  }

  &__works-number{
    color: $col_red_01;
    font-weight: bold;
    font-size: 18px;
    display: block;
    margin-right: 18px;
  }

  &__works-text{
    font-size: 18px;
    display: block;
  }

  &__works-wrapper-image{
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  &__works-image {
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;

    object-fit: contain;
    object-position: center bottom;
  }

  &__benefit {
    background: $col_ping;
  }

  &__benefit-list {
    display: grid;
    margin-bottom: 32px;
  }

  &__benefit-item {
    display: grid;

    svg{
      width: 50px;
      height: 50px;
    }
  }

  &__wrapper-benefit-text{
    display: flex;
    align-self: center;
  }
  &__benefit-text {
    font-size: 18px;
  }
}

@include respond-up (L) {
  .bot-sale {
    &__offer{
      height: 60vh;
    }

    &__block-title{
      margin-bottom: 32px;
    }

    &__block{
      padding: 80px 0 80px;
    }

    &__offer-item{
      height: 70vh;
    }

    &__offer-container {
      padding-top: 200px;
      width: 900px;
      margin: 0 auto;
    }

    &__offer-title{
      margin-bottom: 8px;
      letter-spacing: 3.1px;
    }

    &__offer-for{
      letter-spacing: 3.1px;
      font-weight: bold;
      display: block;
      margin-bottom: 60px;
    }

    &__content {
      grid-template-columns: 1.2fr 0.8fr;
    }

    &__works-wrapper-image{
      height: 370px;
    }

    &__works-image {
      position: absolute;
    }

    &__content-icon {
      svg {
        width: 75px;
        height: 75px;
        margin-bottom: 30px;
      }
    }

    &__benefit-list {
      padding: 0 50px;
    }

    &__benefit-list {
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }

    &__benefit-item {
      grid-template-columns: 50px 1fr;
      grid-gap: 20px;
    }

    &__ease-content {
    }
  }
}

@include respond-down (M) {
  .bot-sale {
    &__offer{
      height: 60vh;
    }

    &__block-title{
      margin-bottom: 24px;
    }

    &__block{
      padding: 60px 0 60px;
    }

    &__offer-container {
      position: absolute;
      width: auto;
      padding: 0;
      bottom: 80px;
      right: 80px;
    }


    &__offer-container {
      padding-top: 150px;
      width: 500px;
      margin: 0 auto;
    }

    &__offer-title{
      margin-bottom: 4px;
    }

    &__works {
      padding: 80px 0 80px 0;
    }

    &__offer-for{
      margin-bottom: 30px;
    }

    &__content {
      grid-template-columns: 1fr 1fr;
    }

    &__content-description {
      letter-spacing: 1.5px;
    }

    &__content-icon {
      svg {
        width: 75px;
        height: 75px;
        margin-bottom: 30px;
      }
    }

    &__works-wrapper-image{
      height: auto;
    }
    &__works-image {
      position: static;
    }

    &__benefit {
      padding: 80px 0 80px 0;
    }

    &__benefit-title {
      margin-bottom: 60px;
    }

    &__benefit-list {
      padding: 0;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }

    &__benefit-item {
      grid-template-columns: 35px 1fr;
      grid-gap: 20px;
    }

  }
}

@include respond-down (S) {
  .bot-sale {
    &__offer{
      height: 50vh;
    }
    &__block-title{
      margin-bottom: 16px;
    }
    &__offer-container {
      width: auto;
    }

    &__block{
      padding: 40px 0 40px;
    }

    &__offer-container {
      position: absolute;
      width: auto;
      padding: 150px 10px 20px;
      bottom: 40px;
      right: 40px;
    }

    &__offer-item{
      height: 75vh;

      &:after{
        opacity: 1;
      }
    }

    &__content-description {
      letter-spacing: 1px;
    }

    &__content {
      padding: 80px 0 80px 0;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: min-content min-content;
      grid-column-gap: 0;
      grid-row-gap: 50px;
    }

    &__content-description {
      margin-bottom: 31px;
      font-size: 14px;
    }

    &__content-icon {
      svg {
        width: 40px;
        height: 40px;
        margin-bottom: 19px;
      }
    }

    &__benefit-list{
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }

    &__benefit-item {

    }
  }
}

@include respond-down (XS) {
  .bot-sale {
    &__benefit-item {
      padding-right: 0;
    }
  }
}

