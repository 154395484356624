.news-preview {
  display: flex;
  flex: 0 0 25%;
  padding: 0 12px;
  margin-bottom: 24px;

  @include respond-down(M){
    flex: 0 0 33.3333333333%;
  }

  @include respond-down(S){
    flex: 0 0 50%;
  }

  @include respond-down(XS){
    flex: 0 0 100%;
  }

  &__row{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__date{
    font-size: 10px;
    color: $col_text_03;
  }

  &__link {
    border-radius: 5px;
    display: block;
    width: 100%;
    //min-height: 320px;
    box-shadow: 3px 3px 10px 0px #a19ca161;
    z-index: 1;
    position: relative;
    overflow: hidden;
    min-height: 380px;

    &:after{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 50%;
      background-color: rgba(43, 43, 43, 0.69);
      top: -50%;
      z-index: 2;
      transition: $default-transition;
    }

    &:before{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 50%;
      background-color: rgba(43, 43, 43, 0.69);
      bottom: -50%;
      z-index: 2;
      transition: $default-transition;
    }

    &:hover{
      &:after{
        top: 0;
      }

      &:before{
        bottom: 0;
      }

      .news-preview__text-info{
        opacity: 1;
        left: 50%;
      }
    }
  }

  &__text-info{
    width: 100%;
    z-index: 3;
    transition-delay: 0.5s;
    transition: $default-transition;
    color: white;
    font-size: 25px;
    position: absolute;
    top: 50%;
    left: -20%;
    transform: translate(-50%, -50%);
    opacity: 0;
    display: block;

    .text{
      text-align: center;
      display: block;
      font-size: 14px;
    }
    .value{

      text-align: center;
      display: block;
    }
  }

  &__image{
    display: block;
    padding-top: 100%;
    width: 100%;
    background: {
      position: center top;
      size: cover;
      repeat: no-repeat;
    };
    margin-bottom: 10px;
  }

  &__bottom{
    display: block;
    padding: 10px;
  }

  &__list-icon{
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    align-items: center;

    svg{
      width: 20px;
      height: 20px;
      margin-left: 5px;

      &:nth-child(2){
        width: 19px;
        height: 19px;
      }
    }
  }

  &__wrapper-text{}

  &__title{
    margin-bottom: 5px;
    display: block;
    font-size: 13px;
    color: $col_text_01;
    font-weight: 600;
    min-height: 32px;
  }

  &__description{
    display: block;
    font-size: 12px;
    color: $col_text_01;

  }
}