.news-block {
  margin-bottom: 50px;
  padding-top: 50px;

  &__content {
    @include respond-down(M){
      margin-top: -33px;
    }
  }

  &__wrapper-button{
    display: flex;
    justify-content: center;
  }

  &__button{
    @include respond-down(M){
      max-width: 250px;
    }
  }

  &__title{
    margin-bottom: 60px;
  }

  &__list {
    .slick-arrow{
      position: absolute;
      z-index: 300;
      overflow: hidden;
      top: -50px;
      text-indent: -10000px;
      display: block;
      width: 30px;
      height: 30px;

      transition: $default-transition;
      background: {
        repeat: no-repeat;
        position: center;
        size: 20px 28px;
      };

      @include respond-down(M){
        width: 20px;
        height: 20px;
        background-size: 15px 18px;
      }

    }

    .slick-next {
      right: 0;
      background-image: url("/static/images/svg/arrow-right.svg");


    }

    .slick-prev {
      right: 60px;
      background-image: url("/static/images/svg/arrow-left.svg");


    }

  }
}