.product-preview {
  padding: 0 12px;
  display: flex;
  margin-bottom: 12px;
  position: relative;

  &__item {

  }

  &__item-link {
    background-color: white;
    display: block;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 3px 3px 10px 0px #a19ca161;
    z-index: 1;
    position: relative;

    &:after{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 50%;
      background-color: rgba(43, 43, 43, 0.69);
      top: -50%;
      z-index: 2;
      transition: $default-transition;
    }

    &:before{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 50%;
      background-color: rgba(43, 43, 43, 0.69);
      bottom: -50%;
      z-index: 2;
      transition: $default-transition;
    }

    &:hover{
      &:after{
        top: 0;
      }

      &:before{
        bottom: 0;
      }

      .product-preview__text-info{
        opacity: 1;
        left: 50%;
      }
    }

    @include respond-down(M){
      &:after{
        display: none;
      }

      &:before{
        display: none;
      }

      .product-preview__text-info{
        display: none;
      }

    }
  }

  &__text-info{
    z-index: 3;
    transition-delay: 0.5s;
    transition: $default-transition;
    color: white;
    font-size: 25px;
    position: absolute;
    top: 50%;
    left: -20%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  &__image {
    height: 230px;
    display: block;
    width: 100%;
    background: {
      repeat: no-repeat;
      position: center right;
      size: cover;
    };

    @include respond-down(L){
      height: 200px;
    }
  }

  &__bottom {
    height: 190px;
    padding: 15px;
    display: block;
    position: relative;

    @include respond-down(M){
      height: 170px;
    }

    @include respond-down(S){
      height: 180px;
    }
  }

  &__name-product {
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
    color: $col_text_01;
    letter-spacing: 1.33px;
    overflow: hidden;

    @include respond-down(L){
      font-size: 16px;
    }
  }

  &__category-product{
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: $col_hot_pink_dark;
  }

  &__description-product {
    display: block;
    font-size: 12px;
    color: #727073;
    letter-spacing: 1px;
    margin-bottom: 20px;

    @include respond-down(L){
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  &__wrapper-price {
    display: flex;
    align-items: center;
    position: absolute;
    left: 15px;
    right: 0;
    bottom: 15px;

  }

  &__new-price {
    font-weight: 700;
    font-size: 25px;
    color: #000000;
    margin-right: 36px;
    letter-spacing: 2.5px;

    svg{
      height: 20px;
    }

    @include respond-down(M){
      font-size: 19px;

      svg{
        height: 14px;
      }
    }
  }

  &__old-price {
    overflow: hidden;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
    letter-spacing: 2px;
    position: relative;
    z-index: 1;

    &:before{
      content: '';
      position: absolute;
      z-index: 2;
      background: red;
      top: 50%;
      left: 0;
      right: 0;
      height: 2px;
    }
    svg{
      height: 17px;
    }
  }


}
