.header {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;

  z-index: 10000;
  transition: all .4s;
  transform: translateY(-99px);

  &_opaсity_no {
    transition: all .4s;
  }

  &_visible {
    transform: translateY(0);

    .header {
      &__menu {
        transition: all .5s ease;
        opacity: 1;
      }
    }
  }

  &__menu-container {
    display: flex;
    justify-content: space-between;
  }

  &__mobile {
    display: none;
  }

  &._pink{
    background-color: rgba(202, 202, 202, 0.14);
    box-shadow: 0px 3px 10px 0px rgba(196,196,196,1);

    &.header_open{
      background: $col_pink_main;
    }

    &.header_opaсity_no {
      background: $col_pink_main;
    }

    .menu-container__logo {
      background: {
        image: url("/static/images/svg/logo.svg");
        position: left 0px center;
        repeat: no-repeat;
      };

      .desc{
        color: $col_text_01;
      }
    }

    .menu-container__link-item{
      color: $col_text_01;
    }
  }

  &._hot_pink{
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.56);
    background-color: rgba(0, 0, 0, 0.25);

    &.header_opaсity_no {
      background: rgba(0, 0, 0, 1);
    }

    .menu-container__logo {
      background: {
        image: url("/static/images/svg/logo_pink.svg");
        position: left 0px center;
        repeat: no-repeat;
      };

      .desc{
        color: white;
      }
    }

    .menu-container__link-item{
      color: white;

      &::before{
        background: $col_hot_pink_dark;
      }
      &::after{
        color: $col_hot_pink_dark;
      }
    }

    &.header_open{
      background: black;
    }

    .header-mobile__hamburger-container{
      .hamburger-inner{
        background: $col_hot_pink_dark;
        &::after{
          background: $col_hot_pink_dark;
        }
        &::before{
          background: $col_hot_pink_dark;
        }
      }
    }

    .header-mobile__link{
      &::before{
        background: rgba(220, 61, 133, 0.71);
      }
      .value{
        color: white;
      }
    }
  }

  @include respond-down(M){
    &__menu-container {
      display: none;
    }

    &__mobile {
      display: flex;
      justify-content: space-between;

    }

    &__menu{
      max-height: 70px;
    }
  }
}

.header_open {
  min-height: 100vh;
  overflow: auto;
  pointer-events: auto;
  transition: all .5s;
  background-color: $col_pink_main;

  .header-mobile {
    transition: all .6s;

    &__menu {
      opacity: 1;
      pointer-events: auto;
      transition: all .6s;
      overflow: auto;
    }
  }
}
