.page-error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__title {
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 600;

  }
  &__wrapper-button {
    display: flex;
    justify-content: center;
  }
}

@include respond-up(L){
  .page-error {
    padding: 100px 0 100px;
    &__title {
      font-size: 130px;
      margin-bottom: 100px;

    }

  }
}

@include respond-down(M){
  .page-error {
    padding: 80px 0 80px;

    &__title {
      font-size: 80px;

      margin-bottom: 80px;

    }

  }
}

@include respond-down(S){
  .page-error {
    &__title {
      font-size: 50px;
      margin-bottom: 50px;

    }

  }
}
