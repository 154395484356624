.button{
  cursor: pointer;
  font-family: $font;
  font-size: 16px;
  color: $col_text_01;
  letter-spacing: 1.48px;
  text-align: center;
  display: flex;
  border-radius: 5px;

  min-width: 250px;
  padding: 15px 20px;
  justify-content: center;
  text-transform: uppercase;

  @include respond-down(S){
    width: 100%;
  }



  &_white_mini{
    text-transform: unset;
    background: #FFFFFF;
    border: 1px solid #979797;
    box-shadow: 2px 3px 3px 0 #FDEAE6;
    border-radius: 10px;
    font-size: 15px;
    color: #141217;
    letter-spacing: 1.5px;
    transition: $default-transition;
    padding: 10px 10px;
    min-width: 0;
    width: auto;



    &:hover{
      box-shadow: 3px 4px 4px 0 #eddcd8;
      background: #e9e9e9;
    }
  }


  &.red{
    background-color: $col_red_01;
    box-shadow: 6px 6px 16px 0px transparent;
    transition: $default-transition;

    &:hover{
      background-color: $col_red_02;
      box-shadow: 6px 6px 16px 0px rgba(196,196,196,1);
    }
  }

  &.hot-pink{
    background-color: $col_hot_pink;
    box-shadow: 6px 6px 16px 0px transparent;
    transition: $default-transition;

    &:hover{
      background-color: $col_hot_pink_dark;
      box-shadow: 6px 6px 16px 0px rgb(58, 16, 35);
    }
  }

  &.up{
    text-transform: uppercase;
  }

  &.forward{
    min-width: 0;
    border: 1px solid darkgrey;
    background: transparent;
    display: flex;
    align-items: center;
    transition: $default-transition-fast;

    .text{
      white-space: nowrap;
      margin-right: 30px;
    }

    svg{
      transform: rotate(180deg);
      width: 35px;
      height: 20px;
      path{
        fill: $col_text_01;
        stroke: $col_text_01;
        transition: $default-transition-fast;
      }


    }

    @include respond-up(L){

      &:hover{
        svg{
          path{
            stroke: $col_red_01;
            fill: $col_red_01;
          }
        }
      }
    }

    @include respond-down(M){
      .text{
        margin-right: 20px;
      }

    }

  }


  &.back-arrow{
    min-width: 0;
    border: 1px solid darkgrey;
    background: transparent;
    display: flex;
    align-items: center;
    transition: $default-transition-fast;

    .text{
      white-space: nowrap;
    }



    svg{
      margin-right: 30px;
      width: 35px;
      height: 20px;
      path{
       fill: $col_text_01;
        stroke: $col_text_01;
        transition: $default-transition-fast;
      }
    }

    @include respond-up(L){

      &:hover{
        svg{
          path{
            stroke: $col_red_01;
            fill: $col_red_01;
          }
        }
      }
    }

    @include respond-down(M){
      svg{
        margin-right: 20px;
      }

    }

    @include respond-down(S){
      flex: 0 0 52px;
      height: 52px;

      padding: 10px;
      justify-content: center;

      .text{
        display: none;
      }

      svg{
        width: 52px;
        height: 52px;
        margin-right: 0;
      }

    }


    &_right{
      svg{
        transform: rotate(180deg);
        margin-right: 0px;
      }

      .text{
        margin-right: 20px;
      }
    }
  }

  &.search{
    min-width: 0;
    border: 1px solid darkgrey;
    background: transparent;
    display: flex;
    align-items: center;
    transition: $default-transition-fast;

    .text{
      white-space: nowrap;
      margin-right: 20px;
    }

    svg{
      width: 20px;

      path{
        fill: $col_text_01;
        stroke: $col_text_01;
        transition: $default-transition-fast;
      }
    }

    @include respond-up(L){
      &:hover{
        svg{
          path{
            stroke: $col_red_01;
            fill: $col_red_01;
          }
        }
      }
    }

    @include respond-down(M){


    }

    @include respond-down(S){
      height: 52px;
      padding: 10px;
      justify-content: center;
    }

  }

  &_white-text{
    color: white;
    font-weight: 400;
  }
}
