.trend-block {
  background-image: $gradient-standart;
  padding: 40px 0;
  &__title {

  }


  &__wrapper-button{
    display: flex;
    justify-content: center;
  }

  &__list {
    padding: 20px 0;
    display: flex;
    margin: 0 -12px 40px;

    .product-preview{
      flex: 0 0 25%;
    }




    @include respond-down(M){
      flex-wrap: wrap;
      padding: 20px 0 40px;
      margin: 0 -12px;

      .product-preview{
        flex: 0 0 50%;
      }
    }


    @include respond-down(S){
      .product-preview{
        flex: 0 0 100%;
      }
    }

  }
}