.success {
  background-image: linear-gradient(-90deg, #FFF9F7 0%, #FDEAE6 100%);

  &__inner {
    padding: 100px 0  90px;
  }

  &__wrapper-svg {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;

    .svg-ok{
      width: 200px;
      height: 200px;

     .svg-ok-inner{
        fill: #00cb00;
      }

      .svg-ok-circle{
        fill: rgba(57, 57, 57, 0.84);
      }
    }
  }

  &__wrapper-title{
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  &__wrapper-text {
    color: $col_text_01;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    .text{
      text-align: center;
      max-width: 800px;
      letter-spacing: 0.03em;
      line-height: 1.2;
    }
  }

  &__wrapper-button {
    display: flex;
    justify-content: center;
  }
}