.video {
  width: 100%;
  height: 100%;
  max-width: 90%;
  &__frame {
      width: 900px;
      height: 500px;

    @include respond-down(M){
      width: 700px;
      height: 500px;

    }

    @include respond-down(S){
      width: 400px;
      height: 250px;
    }

    @include respond-down(XS){
      width: 285px;
      height: 180px;
    }
  }
}