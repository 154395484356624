.meta-product {
  &__list {
    display: flex;
    margin: 0 -12px;

    .product-preview{
      flex: 0 0 25%;
    }

    @include respond-down(M){
      flex-wrap: wrap;

      .product-preview{
        flex: 0 0 50%;
      }
    }

    @include respond-down(XS){
      .product-preview{
        flex: 0 0 100%;
      }
    }
  }
}