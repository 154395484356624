.header-mobile {
  &__menu {
    display: none;
    padding: 30px 0 0 0;

    &.active{
      .header-mobile__link{
        opacity: 1;
      }
      .header-mobile__contact-block{
        .header-mobile__instagram{
            top: 0px;
        }
      }
    }

    @include respond-down(M){
      display: block;
      opacity: 0;
      pointer-events: none;
    }
  }

  &__link{
    position: relative;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    transition: opacity 0.6s, right 0.5s ease-in-out;
    opacity: 0;

    &:before{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.1);
      height: 1px;
      display: block;
      transition: left 1s;
    }

    .value{
      color: $col_text_01;
      font-size: 18px;
      text-transform: uppercase;

      &_new {
        position: relative;

        &:after{
          position: absolute;
          content: "new";
          display: block;
          font-size: 7px;
          font-weight: bold;
          color: red;
          right: -10px;
          top: -10px;
          transition: font-size 0.3s;
        }
      }
    }
  }

  &__contact-block{
    overflow: hidden;
    position: absolute;
    bottom: 20px;
    left: 5%;
    right: 5%;

    display: flex;

  }

  &__instagram{
    position: relative;
    top: 50px;
    transition: top 1s ease-out;
    display: block;
    margin-right: 20px;

    svg.icon{
      width: 30px;
      height: 30px;
    }
  }
}

.header-mobile_open {
  .header-mobile {
    &__menu {
      opacity: 1;
      pointer-events: auto;

    }
  }
}
