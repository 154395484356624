.modal-place {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 80px 35px 80px;

  &__title {
    text-align: center;
    display: block;


  }

  &__link {
    display: block;
    margin-bottom: 20px;
    transition: all 0.4s;
    color: $col_telegram_01;

    &:visited{
      color: $col_telegram_01;
    }
  }

  &__wrapper-image {
    margin-bottom: 40px;
  }

  &__text {
    font-size: 16px;
    color: $col_text_03;
    display: block;
    margin-bottom: 18px;
    text-align: center;
  }

  &__link-about{
    font-size: inherit;
    color: $col_text_01;
  }

  &__wrapper-button {
  }

  &__button {
    margin-bottom: 20px;
  }
}

@include respond-up(L){
  .modal-place{
    &__title {
      font-size: 36px;
      letter-spacing: 2px;
      margin-bottom: 36px;

      a {
        &:hover{
          transition: all 0.4s;
          color: $col_telegram_02;
        }
      }
    }
  }
}

@include respond-down(M) {
  .modal-place {
    padding: 35px 50px;
  }
}

@include respond-down(S) {
  .modal-place {
    padding: 35px;
  }
}
