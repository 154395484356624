.marker{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  color: black;
  font-size: 12px;
  font-weight: 700;
  margin-right: 5px;

  &.vip{
    background: gold;
    ;
  }
  &.new{
    background: black;
    color: white;
  }
  &.stock{
    background: #c70000;
  }
  &.top{
    background: #f56b29;
  }

  span{
    padding-top: 1px;
  }
}

.list-marker{
  display: flex;
  position: absolute;
  top: 5px;
  left: 5px;
}