.error-bot {

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__title {
    text-align: center;
    margin-bottom: 20px;
  }

  &__description {
    margin-bottom: 20px;
  }

  &__link-bot {

  }
}

@include respond-up(L){

}

@include respond-down(M){
  .error-bot {
    padding: 100px 0 50px;
  }
}