.questionnaire-page {
  min-height: 800px;
  padding-top: 100px;
  &__wrapper-title{
    display: flex;
    justify-content: center;
  }
  &__title{
    font-weight: 500;
    flex: 0 1 500px;

    margin-bottom: 40px;
    text-align: center;
  }

  &__wrapper-form {
    display: flex;
    justify-content: center;
  }

  &__form {
    flex: 0 1 500px;
    position: relative;

    &.success{
      .questionnaire-page__success{
        opacity: 1;
      }
    }
  }

  &__success{
    background-color: rgba(255,237,237,1);
    flex-direction: column;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }

  &__icon{
    margin-bottom: 20px;
  }

  &__success-title{
    font-size: 22px;
    text-align: center;
  }

  &__wrapper-button{
    display: flex;
    justify-content: center;
  }

  &__field {
    margin-bottom: 20px;

    &_hidden{
      display: none;
    }
  }

  &__button {

  }

  &__rofl{
    display: block;
    margin-bottom: 5px;
    font-size: 12px;
  }

  &__confidential{
    font-size: 10px;
    color: $col_text_01;
    margin-right: 15px;
    display: block;
    margin-bottom: 30px;
  }

  &__confidential-link{
    font-weight: 700;
    border: 1px solid transparent;
    transition: $default-transition;

    &:hover{
      border-bottom: 1px solid $col_text_01;
    }
  }
}

@include respond-up(L){
  .questionnaire-page {
    &__title{
      font-size: 25px;
    }

    &__wrapper-form {

    }

    &__form {

    }

    &__field {

    }

    &__button {

    }
  }
}

@include respond-down(M){
  .questionnaire-page {
    &__title {
      font-size: 25px;
    }
  }
}



@include respond-down(S){
  .questionnaire-page {
    &__title {
      font-size: 20px;
    }
    &__rofl{
      font-size: 14px;
    }
  }
}